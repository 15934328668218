import { useContext, useEffect, useReducer, useState } from 'react';
import { Box, Typography, FormControl, InputLabel, OutlinedInput, InputAdornment, Button } from "@mui/material";
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth, signInWithPhoneNumber } from 'firebase/auth';
import AuthContext from '../../store/auth-context';
import LanguageSwitcher from '../../components/LanguageSwitcher';
import { useTranslation } from 'react-i18next';

const auth = getAuth();
const VerifyPage = () => {
    const { t } = useTranslation();
    const authCtx = useContext(AuthContext);
    const { state } = useLocation();

    const navigate = useNavigate();
    const [otp, setOtp] = useState();
    const [timerMinutes, setTimerMinutes] = useState('00');
    const [timerSeconds, setTimerSeconds] = useState(59);


    const onCodeChange = (e) => {
        let val = e.target.value;
        if (val.length < 7) {
            setOtp(val);
        };
    }

    const startTimerHandler = () => {
        setTimerMinutes('00');
        setTimerSeconds(59);
        var timer = setInterval(() => {

            setTimerMinutes(prev => {
                return '00'
            });

            setTimerSeconds(prev => {
                if (prev === 0) {

                    // console.log('timer finished');
                    clearInterval(timer);
                    return 0
                } else {
                    return prev - 1;
                }
            });
        }, 1000);
    }

    useEffect(() => {
        startTimerHandler();

    }, []);


    const registerSubmitHandler = () => {



        if (otp.length === 6) {
            window.confirmationResult.confirm(otp).then((result) => {
                // User signed in successfully.
                const user = result.user;
                console.log('firebase result', result);


                if (state?.type === 'forgotPassword') {
                    auth.currentUser.getIdTokenResult().then(user => {
                        console.log('token', user.token);
                        authCtx.setAccessToken(user.token);
                        navigate('/reset-password');
                    });
                } else {
                    navigate('/register');
                }

            }).catch((error) => {
                // User couldn't sign in (bad verification code?)
                // ...
                console.log('error', error);
            });

        } else {
            setOtp(undefined);
        };
    }

    const resendCodeHandler = () => {


        const appVerifier = window.recaptchaVerifier;

        window.grecaptcha.reset(window.recaptchaWidgetId);

        // Or, if you haven't stored the widget ID:
        window.recaptchaVerifier.render().then(function (widgetId) {
            window.grecaptcha.grecaptcha.reset(widgetId);
        });

        signInWithPhoneNumber(auth, `+20${authCtx.phone}`, appVerifier)
            .then((confirmationResult) => {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                startTimerHandler();
                window.confirmationResult = confirmationResult;
                // ...
                console.log('success resend');
            }).catch((error) => {
                // Error; SMS not sent
                // ...
                console.log('error', error);
            });
    }

    return (<Box>
        <LanguageSwitcher/>
        <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: '15px' }}>{t('Confirm Phone Number')}</Typography>
        <Typography variant="body2" sx={{ marginBottom: '30px' }}>{t('Check SMS sent to your phone')} { authCtx.phone && <strong>+20{authCtx.phone}</strong>}</Typography>

        <Box sx={{ marginBottom: '15px' }}>
            <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-Code">{t('Code')}</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-Code"
                    autoFocus
                    value={otp}
                    label={t("Code")}
                    placeholder={t('please type numbers in english')}
                    max="6"
                    onChange={onCodeChange}
                />
            </FormControl>
            {!otp === '' && <Typography fontSize="12px" sx={{ color: '#f00', py: '10px' }}>{t('OTP is Required')}</Typography>}
            <Box sx={{ pt: '16px' }}>
                {timerSeconds > 0 && <Typography variant="body2" textAlign="center"> {t('You can resend new code after')} {timerMinutes}:{timerSeconds >= 10 ? timerSeconds : '0' + timerSeconds}  {t('seconds')}</Typography>}
                {timerSeconds === 0 && <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-end", alignItems: 'center' }}><Typography>{t("Didnt receive OTP?")}</Typography><Button onClick={resendCodeHandler}>{t("Resend")}</Button></Box>}
            </Box>



        </Box>

        <Button variant='contained' disabled={!otp || otp.length !== 6} color='primary' onClick={registerSubmitHandler} fullWidth>{t('Verify Mobile Number')}</Button>
    </Box>)
};

export default VerifyPage