import './App.css';
import { useState, useContext, useEffect } from 'react';
import { Container, Card, CardContent, Typography } from '@mui/material';
import { Routes, Route, Navigate } from 'react-router-dom';
import ConfirmPhonePage from './pages/ConfirmPhone/ConfirmPhone';
import VerifyPage from './pages/Verify/Verify';
import RegisterPage from './pages/Register/Register';
import AuthContext from './store/auth-context';
import ForgotPasswordPage from './pages/ForgotPassword/ForgotPassword';
import ResetPasswordPage from './pages/ResetPassword/ResetPassword';
import {config} from './config/config';
import { useTranslation } from 'react-i18next';


const EmptyView = (props) => {
  useEffect(() => {
    if(window.history.length > 1){
      window.history.back();
      console.log(window.history.length);
    }else{
      window.close();
    }

  }, []);
  return <Typography>{props.source === '' ? 'Validating Domain' : 'Invalid Domain'}</Typography>
}


function App() {
  const { i18n } = useTranslation();
  const authCtx = useContext(AuthContext);
  const queryParams = new URLSearchParams(window.location.search);
  const domainParam = queryParams.get("domain");
  const language = queryParams.get("lang");

  useEffect(() => {
    authCtx.verifyDomain(domainParam);
    if(language){
      i18n.changeLanguage(language);
    }
  }, [domainParam,language]);

  return (
    <div>
      <Container maxWidth="xs" sx={{ paddingTop: '100px' }}>
        <Card elevation={2}>
          <CardContent>
         {  !config.subdomain && <Routes>
              <Route path='/' element={<EmptyView />} />
            </Routes>}
           { config.subdomain && <>
            <Routes>
              <Route path='/' element={<ConfirmPhonePage />} />
              <Route path='/verify' element={authCtx.phone ? <VerifyPage /> : <Navigate to='/' />} />
              <Route path='/register' element={<RegisterPage />} />
              <Route path='/forgot-password' element={<ForgotPasswordPage />} />
              <Route path='/reset-password' element={authCtx.phone ? <ResetPasswordPage /> : <Navigate to='/' /> } />
            </Routes>
            </>}
          </CardContent>
        </Card>
        <div id='sign-in-button'></div>
        <div id="recaptcha-container"></div>
      </Container>
    </div>
  );
}


export default App;
