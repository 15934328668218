import axios from "axios";

const queryParams = new URLSearchParams(window.location.search);
let subdomain = queryParams.get("domain");
let redirect = queryParams.get("redirect");
let lang = queryParams.get("lang");
let developmentMode = false;
const domain = 'gromenu';

const subdomainSession = sessionStorage.getItem('gromenu_domain');
const redirectSession = sessionStorage.getItem('gromenu_redirect');
const languageSession = sessionStorage.getItem('gromenu_redirect');

if (subdomain && !subdomainSession) {
  sessionStorage.setItem('gromenu_domain', subdomain);
} else {
  subdomain = subdomainSession;
}

if (redirect && !redirectSession) {
  sessionStorage.setItem('gromenu_redirect', redirect);
} else {
  redirect = redirectSession;
}

if (lang && !languageSession) {
  sessionStorage.setItem('gromenu_lang', lang);
} else {
  lang = languageSession;
}

const token = sessionStorage.getItem('gromenu_auth');
const axiosConfig = axios.create({
  baseURL: `https://${subdomain}.${domain}.com/api`,headers:{
    'Authorization':`Bearer 142|7WT6zjQUx4jzC2B0F7e8l2vKOZWWxXMHImvKlOXr`
  }
})

export const config = {
  developmentMode: developmentMode,
  domain: domain,
  subdomain: subdomain,
  redirect:redirect,
  language:lang
};

export default axiosConfig;


