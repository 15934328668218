import { useContext, useEffect, useReducer, useState } from 'react';
import { Box,IconButton, Typography, FormControl, InputLabel, OutlinedInput, InputAdornment, Button } from "@mui/material";
import validator from 'validator';
import AuthContext from '../../store/auth-context';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LanguageSwitcher from '../../components/LanguageSwitcher';
import { useTranslation } from 'react-i18next';

const firstNameReducerFunc = (state, action) => {
    return { value: action.value, invalid: action.value ? action.value.length < 2 : false, required: action?.value?.length < 1 }
}

const lastNameReducerFunc = (state, action) => {
    return { value: action.value, invalid: action.value ? action.value.length < 2 : false, required: action?.value?.length < 1 }
}


const passwordReducerFunc = (state, action) => {
    return { value: action.value, invalid: !validator.isStrongPassword(action?.value,{minSymbols:0,minUppercase:0,minLength:6}), required: action?.value?.length < 1 }
}


const RegisterPage = () => {
    const [showPassword, setShowPassword] = useState(false);
    const { t } = useTranslation();

    const authCtx = useContext(AuthContext);

    const [firstNameVal, dispatchFirstName] = useReducer(firstNameReducerFunc, { value: undefined, invalid: undefined, required: undefined })
    const [lastNameVal, dispatchLastName] = useReducer(lastNameReducerFunc, { value: undefined, invalid: undefined, required: undefined })

    const [passwordVal, dispatchPassword] = useReducer(passwordReducerFunc, { value: undefined, invalid: undefined, required: undefined })

    const [submitDisabled, setSubmitDisabled] = useState(true);

    const registerHandler = () => {
        console.log({
            name: firstNameVal.value+' '+lastNameVal.value,
            phone: 0+authCtx.phone,
            password: passwordVal.value
        })
        authCtx.register(authCtx.source, {
            name: firstNameVal.value+' '+lastNameVal.value || '',
            phone: 0+authCtx.phone,
            password: passwordVal.value
        });
    };



    return (<Box>
        <LanguageSwitcher/>
        <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: '15px' }}>{t('Register')}</Typography>
        <Typography variant="body2" sx={{ marginBottom: '30px' }}>{t('Create your account')}</Typography>

        <Box sx={{ marginBottom: '15px' }}>
            <Box sx={{ marginBottom: '16px' }}>
                <FormControl fullWidth variant="outlined" >
                    <InputLabel htmlFor="outlined-adornment-name">{t('First Name')}</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-name"
                   
                        label={t("First Name")}
                        onChange={(e) => { dispatchFirstName({ value: e.target.value }); }}
                    />
                </FormControl>
                {firstNameVal.invalid && !firstNameVal.required && <Typography fontSize="12px" sx={{ color: '#f00', py: '10px' }} >{t('First Name is invalid')}</Typography>}
                {firstNameVal.required && <Typography fontSize="12px" sx={{ color: '#f00', py: '10px' }}>{t('First Name is Required')}</Typography>}
            </Box>

            <Box sx={{ marginBottom: '16px' }}>
                <FormControl fullWidth variant="outlined" >
                    <InputLabel htmlFor="outlined-adornment-name">{t('Last Name')}</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-name"
                        label={t("Last Name")}
                        onChange={(e) => { dispatchLastName({ value: e.target.value }); }}
                    />
                </FormControl>
                {lastNameVal.invalid && !firstNameVal.required && <Typography fontSize="12px" sx={{ color: '#f00', py: '10px' }} >{t('Last Name is invalid')}</Typography>}
                {lastNameVal.required && <Typography fontSize="12px" sx={{ color: '#f00', py: '10px' }}>{t("Last Name is Required")}</Typography>}
            </Box>
            <input type='hidden' value={authCtx.phone} name="email" id="email" />
            <Box sx={{ marginBottom: '16px' }}>
            <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">{t("Password")}</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    label={t("Password")}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => setShowPassword((prev) => !prev)}
                                aria-label="toggle password visibility"

                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    onChange={(e) => { dispatchPassword({ value: e.target.value });  }}
                />
            </FormControl>
                <Typography sx={{pt:'10px'}} fontSize="12px">{t('Password must be at least 6 characters containing a number')}</Typography>
            {passwordVal.invalid && !passwordVal.required && <Typography fontSize="12px" sx={{ color: '#f00', py: '10px' }} >{t('Password is weak')}</Typography>}
            {passwordVal.required && <Typography fontSize="12px" sx={{ color: '#f00', py: '10px' }}>{t('Password is Required')}</Typography>}
            </Box>
        </Box>


        <Button variant='contained' disabled={( !passwordVal.value || passwordVal.required || passwordVal.invalid) || ( !firstNameVal.value || firstNameVal.required || firstNameVal.invalid) || ( !lastNameVal.value || lastNameVal.required || lastNameVal.invalid)} color='primary' onClick={registerHandler} fullWidth>{t('Save and Continue')}</Button>
    </Box>)
};

export default RegisterPage;