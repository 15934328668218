
import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';
import App from './App';

import ThemeContextProvider from './theme/provider';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { AuthProvider } from './store/auth-context';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import './config/i18n';
import { useTranslation } from 'react-i18next';

const Main = () => {

  const { i18n } = useTranslation();
  console.log(i18n.resolvedLanguage);

  const cacheRtl = createCache({
    key: i18n.resolvedLanguage === 'ar' ? 'muirtl' : 'muiltr',
    stylisPlugins: i18n.resolvedLanguage === 'ar' ? [prefixer, rtlPlugin] : [],
  });

  return <CacheProvider value={cacheRtl}>
    <ThemeContextProvider>
      <AuthProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthProvider>
    </ThemeContextProvider>
    </CacheProvider>
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Main />);

