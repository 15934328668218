import { useEffect, useReducer, useContext, useState } from 'react';
import { Box, ButtonBase,Divider, Typography, FormControl, InputLabel, OutlinedInput, InputAdornment, Button } from "@mui/material";
import {Link, useNavigate } from 'react-router-dom';
import FirebaseApp from '../../config/firebase';
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth';
import AuthContext from '../../store/auth-context';
import validator from 'validator';
import LanguageSwitcher from '../../components/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import { charat } from 'stylis';

const phoneReducerFunc = (state, action) => {
    return { value: action.value, invalid: !validator.isMobilePhone(0+action.value), required: action.value.length === 0 }
}

const auth = getAuth(FirebaseApp);

const ForgotPasswordPage = () => {
    const { t,i18n } = useTranslation();
    const navigate = useNavigate();
    const [phoneVal, dispatchPhone] = useReducer(phoneReducerFunc, { value: undefined, invalid: undefined, required: undefined })
    const queryParams = new URLSearchParams(window.location.search);
    const domainParam = queryParams.get("domain");
    const authCtx = useContext(AuthContext);
    const [phoneNotExist, setPhoneNotExist] = useState();
    const [submitDisabled, setSubmitDisabled] = useState(true);

    useEffect(() => {
        if (window.recaptchaVerifier) {
            window.recaptchaVerifier.render().then(function (widgetId) {
                window.grecaptcha.grecaptcha.reset(widgetId);
            });
        };


        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                // ...
            },
            'expired-callback': () => {
                // Response expired. Ask user to solve reCAPTCHA again.
                // ...
            }
        }, auth);

        window.recaptchaVerifier.render().then((widgetId) => {
            window.recaptchaWidgetId = widgetId;
        });
    }, []);

    const onPhoneChange = (e) => {
        let phone = e.target.value;
        if(phone.charAt(0) === '0'){
        
            phone = phone.substring(1);
        }
       dispatchPhone({ value: phone });
     
        console.log('has zero',phone);

        if (phoneVal.value !== phone) {
            setSubmitDisabled(false);
            setPhoneNotExist(false);
        }
    }

    const sendOtpHandler = () => {




        if (phoneVal.value || !phoneNotExist) {
            authCtx.accountExists(phoneVal.value);

            const appVerifier = window.recaptchaVerifier;

            signInWithPhoneNumber(auth, `+20${phoneVal.value}`, appVerifier)
                .then((confirmationResult) => {
                    // SMS sent. Prompt user to type the code from the message, then sign the
                    // user in with confirmationResult.confirm(code).
                    window.confirmationResult = confirmationResult;
                    // ...
                    console.log('success');
                    // authCtx.updateContext(phoneVal.value,undefined);
                    navigate('/verify', { state: { type:'forgotPassword' } });
                }).catch((error) => {
                    // Error; SMS not sent
                    // ...
                    console.log('error', error);
                    window.location.reload();
                });

        };
    };

    const onSubmitHandler = () => {
        authCtx.accountExists(phoneVal.value).then(data => {
            console.log(data);
            if (data.valid === false) {
                setPhoneNotExist(true);
                setSubmitDisabled(true);
            } else {
                setPhoneNotExist(false);
                setSubmitDisabled(false);
                sendOtpHandler();
            }
        });
    }



    return (<Box>
        <LanguageSwitcher/>
        <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: '15px' }}>{t('Forgot Password')}</Typography>
        <Typography variant="body2" sx={{ marginBottom: '30px' }}>{t("You'll receive a one time verification code by SMS shortly.")}</Typography>

        <Box sx={{ marginBottom: '15px' }}>
            <FormControl fullWidth variant="outlined">
            <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',marginBottom:'10px'}}>
            <img src={ require('../../assets/images/phoneIcon.png')} style={{marginRight:i18n.resolvedLanguage === 'en'? '10px':'0px',marginLeft:i18n.resolvedLanguage === 'ar'? '10px':'0px'}} />
                    <Typography color="#555" fontSize="14px">{t('Mobile number')}</Typography>
                </Box>
                { i18n.resolvedLanguage === 'en' && <OutlinedInput
                    type="tel"
                    id="outlined-adornment-mobile"
                    placeholder={t('Enter your mobile number...')}
                    value={phoneVal.value}
                    startAdornment={<InputAdornment position="start"><Box sx={{ display: 'flex', flexDirection: 'row' }}><Box sx={{ borderRight: '2px solid #eee', position: 'absolute', top: 0, height: '100%', paddingRight: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img src={require('../../assets/images/egyFlag.png')} /></Box><Box sx={  { paddingLeft: '60px' }}>+20</Box></Box></InputAdornment>}
                    onChange={onPhoneChange}
                />}
                  { i18n.resolvedLanguage === 'ar' && <OutlinedInput
                 
                    type="tel"
                    inputProps={{style:{direction:'ltr',textAlign:'left'}}}
                    id="outlined-adornment-mobile"
                    placeholder={t('Enter your mobile number...')}
                    value={phoneVal.value}
                    endAdornment={<InputAdornment position="end"><Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}><Box sx={{ borderLeft: '2px solid #eee', position: 'absolute', top: 0, height: '100%', paddingLeft: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img src={require('../../assets/images/egyFlag.png')} /></Box><Box sx={  { paddingRight: '60px' }}>+20</Box></Box></InputAdornment>}
                    onChange={onPhoneChange}
                />}
            </FormControl>
            {phoneVal.invalid && !phoneVal.required && <Typography fontSize="12px" sx={{ color: '#f00', py: '10px' }} >{t('Phone is invalid')}</Typography>}
            {phoneVal.required && <Typography fontSize="12px" sx={{ color: '#f00', py: '10px' }}>{t('Phone is Required')}</Typography>}
            {phoneNotExist && <Typography fontSize="12px" sx={{ color: '#f00', py: '10px' }}>{t('Phone do not exist')}</Typography>}
        </Box>

        <Button variant='contained' disabled={phoneVal.required || phoneVal.invalid || submitDisabled} color='primary' onClick={onSubmitHandler} fullWidth>{t('Verify Mobile Number')}</Button>
        <Box>
            <ButtonBase style={{ marginBottom:'30px',padding: '10px 0px', width: '100%', justifyContent: 'flex-start' }} onClick={() => window.history.back()}>
              <img src={require('../../assets/images/returnIcon.png')} style={{marginRight: i18n.resolvedLanguage === 'en'? '15px':'0px',marginLeft: i18n.resolvedLanguage === 'ar'? '15px':'0px',}} />
              <Typography fontSize="16px" textAlign="left" color="#000" >{t('Back to Registeration')}</Typography>
              <img src={i18n.resolvedLanguage === 'en'? require('../../assets/images/arrowIcon.png'): require('../../assets/images/arrowIconLeft.png')} style={{marginLeft: i18n.resolvedLanguage === 'en'? 'auto':'0px',marginRight: i18n.resolvedLanguage === 'ar'? 'auto':'0px',}} />
            </ButtonBase>
           
      
            <Box style={{ padding: '7px 20px', width: '100%', justifyContent: 'flex-start' }} onClick={() => window.history.back()}>
                <Typography fontSize="14px" textAlign="left" ><a href="https://gromenu.com/terms-conditions" target='_blank'>{t('Terms and Condition Apply')}</a></Typography>
            </Box>

        </Box>
    </Box>)
};

export default ForgotPasswordPage;