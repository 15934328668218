import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA0goDu7Z8E6ec1XRpR_3AVeN0F-RJ1HD0",
  authDomain: "gromenu-new.firebaseapp.com",
  projectId: "gromenu-new",
  storageBucket: "gromenu-new.appspot.com",
  messagingSenderId: "21414311028",
  appId: "1:21414311028:web:4a43088b51c46ff4e34d21"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;