import { Box, Button } from "@mui/material"
import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();


    const switchArLangHandler = () => {

        i18n.changeLanguage('ar').then((data) => {
            console.log(data);
        });
    }

    const switchEnLangHandler = () => {

        i18n.changeLanguage('en').then((data) => {
            console.log(data);
        });
    }

    return <Box sx={{position:'relative'}}>
        {i18n.resolvedLanguage === 'en' && <Button onClick={switchArLangHandler} sx={{position:'absolute',top:'-5px',right:'-15px'}}>عربي</Button>}
        {i18n.resolvedLanguage === 'ar' && <Button onClick={switchEnLangHandler} sx={{position:'absolute',top:'-5px',right:'-15px'}}>En</Button>}
    </Box>
};

export default LanguageSwitcher;