import { createContext, useState } from "react";
import axios from "axios";
import { config } from '../config/config';
import axiosConfig from "../config/config";

const AuthContext = createContext({
    token: undefined,
    access_token: undefined,
    source: '',
    phone: '',
    resetPassword: (pass, confirm) => { },
    storeToken: (token) => { },
    setAccessToken: (token) => { },
    verifyDomain: (subdomain) => { },
    register: (subdomain, data) => { },
    accountExists: (phone) => { }
});


export const AuthProvider = (props) => {
    const [token, setToken] = useState();
    const [source, setSource] = useState('');
    const [phone, setPhone] = useState();
    const [accessToken, setAccessToken] = useState();
    const [redirect, setRedirect] = useState();

    const authCtx = {
        token: token,
        source: source,
        redirect: redirect,
        phone: phone,
        access_token: accessToken,
        setAccessToken: (token) => {
            setAccessToken(token);
        },
        storeToken: (token) => {
            setToken(token);
            sessionStorage.setItem('gromenu_auth', token);
        },
        accountExists: async (phone) => {
            try {
                const phoneCheckRes = await axiosConfig.post(`/customer/phone-check`, {
                    'phone': 0 + phone
                });

                if (phoneCheckRes.data.valid === false) {
                    console.log('phone not exist');
                    setPhone(phone);
                };

                if (phoneCheckRes.data.valid === true) {
                    console.log('phone exist');
                    setPhone(phone);
                }

                return phoneCheckRes.data;

            } catch (error) {
                console.log(error.response);
                console.log('error phoneCheckRes', error);
            }
        },
        verifyDomain: async (source) => {
            console.log(source);

            try {
                const subdomainCheckRes = await axios.post('https://gromenu.com/api/domain/check', {
                    domain: `${source}.gromenu.com`
                });
                setSource(source);
                console.log(subdomainCheckRes);
            } catch (error) {
                console.log('error subdomainCheckRes', error);
                setSource(undefined);
            }

        },
        register: async (source, data) => {
            console.log('phone', data.phone);
            try {
                const registerRes = await axiosConfig.post(`/customer/register`, {
                    name: data.name,
                    password: data.password,
                    phone: data.phone
                });
                sessionStorage.removeItem('gromenu_auth');
                sessionStorage.removeItem('gromenu_domain');
                if (config.redirect) {
                    window.location.href = config.developmentMode ? `http://localhost:3000/${config.redirect}?token=${registerRes.data.token}` : `https://${config.subdomain}.${config.domain}.com/menu/${config.redirect}?token=${registerRes.data.token}`;
                };
            } catch (error) {
                console.log(error)
            }
        },
        resetPassword: async (pasword, confirmPassword) => {
            console.log('phone',phone);
            const data = {
                password: pasword,
                phone:0 +phone,
                id_token: accessToken
            };

            try {
                const resetPasswordReq = await axiosConfig.post('/customer/forget-password', data);
                console.log('resetPasswordReq', resetPasswordReq);
                window.location.href = config.developmentMode ? `http://localhost:${window.location.port}/login` : `https://${config.subdomain}.${config.domain}.com/menu/login`;
            } catch (error) {
                console.log(error);
            }
        }
    }

    return <AuthContext.Provider value={authCtx}>{props.children}</AuthContext.Provider>

};

export default AuthContext;

