import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const ThemeContextProvider = (props) => {
const { i18n } = useTranslation();
    const theme = createTheme({
        direction: i18n.resolvedLanguage === 'ar' ? 'rtl' : 'ltr',
        typography: {
            fontFamily: [
                i18n.resolvedLanguage === 'ar' ? ["Vazirmatn"] : 'Roboto'
            ].join(','),
        },
        status: {
            danger: '#e53e3e',
        },
        palette: {
            primary: {
                main: '#000',
            },
        },
    });



    return <ThemeProvider theme={theme}>
        {props.children}
    </ThemeProvider>
};

export default ThemeContextProvider;