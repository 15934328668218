import { useEffect, useReducer, useContext, useState } from 'react';
import { Box, Typography, FormControl, InputLabel, OutlinedInput, InputAdornment, Button, ButtonBase, Divider } from "@mui/material";
import { Link, useNavigate } from 'react-router-dom';
import FirebaseApp from '../../config/firebase';
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth';
import AuthContext from '../../store/auth-context';
import validator from 'validator';
import { config } from '../../config/config';
import LanguageSwitcher from '../../components/LanguageSwitcher';
import { useTranslation } from 'react-i18next';

const phoneReducerFunc = (state, action) => {
    const regexPattern = /^01[0125][0-9]{8}$/;
    return { value: action.value, invalid: action.value ? !regexPattern.test(0 + action.value) : false, required: action.value.length === 0 }
}

const auth = getAuth(FirebaseApp);

const ConfirmPhonePage = () => {


    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [phoneVal, dispatchPhone] = useReducer(phoneReducerFunc, { value: undefined, invalid: undefined, required: undefined })
    const queryParams = new URLSearchParams(window.location.search);
    const domainParam = queryParams.get("domain");
    const authCtx = useContext(AuthContext);
    const [phoneExist, setPhoneExist] = useState();
    const [submitDisabled, setSubmitDisabled] = useState(true);

    useEffect(() => {



        if (window.recaptchaVerifier) {
            window.recaptchaVerifier.render().then(function (widgetId) {
                if(window.grecaptcha.grecaptcha){
                    window.grecaptcha.grecaptcha.reset(widgetId);
                }else{
                    window.location.reload();
                }
                
            });
        };


        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                // ...
            },
            'expired-callback': () => {
                // Response expired. Ask user to solve reCAPTCHA again.
                // ...
            }
        }, auth);

        window.recaptchaVerifier.render().then((widgetId) => {
            window.recaptchaWidgetId = widgetId;
        });
    }, []);

    const onPhoneChange = (e) => {
        let phone = e.target.value;
        if(phone.charAt(0) === '0'){
            phone = phone.substring(1);
        };
        dispatchPhone({ value: phone });
        console.log(phone);


        if (phoneVal !== phone) {
            setSubmitDisabled(false);
            setPhoneExist(false);
        }
    }

    const sendOtpHandler = () => {
        if (phoneVal.value || !phoneExist) {
            const appVerifier = window.recaptchaVerifier;
            signInWithPhoneNumber(auth, `+20${phoneVal.value}`, appVerifier)
                .then((confirmationResult) => {
                    // SMS sent. Prompt user to type the code from the message, then sign the
                    // user in with confirmationResult.confirm(code).
                    window.confirmationResult = confirmationResult;
                    // ...
                    console.log('success');
                    // authCtx.updateContext(phoneVal.value,undefined);
                    navigate('/verify');
                }).catch((error) => {
                    // Error; SMS not sent
                    // ...
                    console.log('error', error);
                });

        };
    };

    const onSubmitHandler = () => {
        authCtx.accountExists(phoneVal.value).then(data => {
            console.log(data);
            if (data.valid === true) {
                setPhoneExist(true);
                setSubmitDisabled(true);
            } else {
                setPhoneExist(false);
                setSubmitDisabled(false);
                sendOtpHandler();
            }
        });
    }



    return (<Box>

        <LanguageSwitcher/>
        <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: '15px' }}>{t("Verify Phone")}</Typography>
        <Typography variant="body2" sx={{ marginBottom: '30px' }}> {t("You'll receive a one time verification code by SMS shortly.")} </Typography>

        <Box sx={{ marginBottom: '15px' }}>
            <FormControl fullWidth variant="outlined">
                <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',marginBottom:'10px'}}>
                    <img src={ require('../../assets/images/phoneIcon.png')} style={{marginRight:i18n.resolvedLanguage === 'en'? '10px':'0px',marginLeft:i18n.resolvedLanguage === 'ar'? '10px':'0px'}} />
                    <Typography color="#555" fontSize="14px">{t('Mobile number')}</Typography>
                </Box>
            
             { i18n.resolvedLanguage === 'en' && <OutlinedInput
                    type="tel"
                    id="outlined-adornment-mobile"
                    value={phoneVal.value}
                    placeholder={t('Enter your mobile number...')}
                    startAdornment={<InputAdornment position="start"><Box sx={{ display: 'flex', flexDirection: 'row' }}><Box sx={{ borderRight: '2px solid #eee', position: 'absolute', top: 0, height: '100%', paddingRight: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img src={require('../../assets/images/egyFlag.png')} /></Box><Box sx={  { paddingLeft: '60px' }}>+20</Box></Box></InputAdornment>}
                    onChange={onPhoneChange}
                />}
                  { i18n.resolvedLanguage === 'ar' && <OutlinedInput
                    type="tel"
                    value={phoneVal.value}
                    inputProps={{style:{direction:'ltr',textAlign:'left'}}}
                    id="outlined-adornment-mobile"
                    placeholder={t('Enter your mobile number...')}
                    endAdornment={<InputAdornment position="end"><Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}><Box sx={{ borderLeft: '2px solid #eee', position: 'absolute', top: 0, height: '100%', paddingLeft: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img src={require('../../assets/images/egyFlag.png')} /></Box><Box sx={  { paddingRight: '60px' }}>+20</Box></Box></InputAdornment>}
                    onChange={onPhoneChange}
                />}
            </FormControl>
            {phoneVal.invalid && !phoneVal.required && <Typography fontSize="12px" sx={{ color: '#f00', py: '10px' }} >{t('Phone is invalid')}</Typography>}
            {phoneVal.required && <Typography fontSize="12px" sx={{ color: '#f00', py: '10px' }}>{t('Phone is Required')}</Typography>}
            {phoneExist && <Typography fontSize="12px" sx={{ color: '#f00', py: '10px' }}>{t('Phone already exist')}</Typography>}
        </Box>

        <Button variant='contained' disabled={phoneVal.required || phoneVal.invalid || submitDisabled} color='primary' onClick={onSubmitHandler} fullWidth sx={{ marginBottom: '15px' }}>{t('Verify Mobile Number')}</Button>
        <Box>
            <ButtonBase style={{ padding: '10px 0px', width: '100%', justifyContent: 'flex-start' }} onClick={() => window.history.back()}>
              <img src={require('../../assets/images/returnIcon.png')} style={{marginRight: i18n.resolvedLanguage !== 'ar'? '15px':'0px',marginLeft: i18n.resolvedLanguage === 'ar'? '15px':'0px',}} />
              <Typography fontSize="16px" textAlign="left" color="#000" >{t('Back to login screen')}</Typography>
              <img src={ i18n.resolvedLanguage !== 'ar'? require('../../assets/images/arrowIcon.png'): require('../../assets/images/arrowIconLeft.png')} style={{marginLeft: i18n.resolvedLanguage !== 'ar'? 'auto':'0px',marginRight: i18n.resolvedLanguage === 'ar'? 'auto':'0px',}} />
            </ButtonBase>
            <Divider />
            <ButtonBase style={{ marginBottom:'30px', padding: '10px 0px', width: '100%', justifyContent: 'flex-start' }} component={Link} to="/forgot-password">
            <img src={require('../../assets/images/passwordIcon.png')} style={{marginRight: i18n.resolvedLanguage !== 'ar'? '15px':'0px',marginLeft: i18n.resolvedLanguage === 'ar'? '15px':'0px',}} />
                <Typography fontSize="16px" textAlign="left" color="#000" >{t('Forget Password')}</Typography>
                <img src={i18n.resolvedLanguage !== 'ar'? require('../../assets/images/arrowIcon.png'): require('../../assets/images/arrowIconLeft.png')} style={{marginLeft: i18n.resolvedLanguage !== 'ar'? 'auto':'0px',marginRight: i18n.resolvedLanguage === 'ar'? 'auto':'0px',}} />
            </ButtonBase>
      
            <Box style={{ padding: '7px 7px',  justifyContent:  i18n.resolvedLanguage !== 'ar'? 'flex-start':'flex-end' }} onClick={() => window.history.back()}>
                <Typography fontSize="14px" textAlign={i18n.resolvedLanguage !== 'ar'? 'left':'right'} ><a href="https://gromenu.com/terms-conditions" target='_blank'>{t('Terms and Condition Apply')}</a></Typography>
            </Box>

        </Box>
    </Box>)
};

export default ConfirmPhonePage;