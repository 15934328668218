import { useEffect, useReducer, useContext, useState } from 'react';
import { Box, Typography, IconButton, FormControl, InputLabel, OutlinedInput, InputAdornment, Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../store/auth-context';
import validator from 'validator';
import axiosConfig, { config } from '../../config/config';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../../components/LanguageSwitcher';

const passwordReducerFunc = (state, action) => {
    return { value: action.value, weak: !validator.isStrongPassword(action?.value,{minSymbols:0,minUppercase:0,minLength:6}), required: action?.value?.length < 1 }
}



const ResetPasswordPage = () => {
    const {t,i18n } = useTranslation();
    const [passwordVal, dispatchPassword] = useReducer(passwordReducerFunc, { value: undefined, weak: undefined, required: undefined })
    const authCtx = useContext(AuthContext);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [passwordConfirm, setPasswordConfirm] = useState();
    const [passwordMatch, setPasswordMatch] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const onPasswordChange = (e) => {
        let password = e.target.value;
        dispatchPassword({ value: password });
        setSubmitDisabled(false);
    }

    const onConfirmPasswordChange = (e) => {
        let confirmPassword = e.target.value;
        setPasswordConfirm(confirmPassword);
        setSubmitDisabled(false);
    }

    const onSubmitHandler = () => {
      authCtx.resetPassword(passwordVal.value,passwordConfirm);
    }



    return (<Box>
        <LanguageSwitcher/>
        <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: '15px' }}>{t('Reset Password')}</Typography>
        <Typography variant="body2" sx={{ marginBottom: '30px' }}>{t('Create Strong remmemberable password')}</Typography>

        <Box sx={{ marginBottom: '15px' }}>
           
           <Box sx={{ marginBottom: '16px' }}>
           <FormControl fullWidth variant="outlined" >
                <InputLabel htmlFor="outlined-adornment-password">{t('Password')}</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-password"
                    label={t("Password")}
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => setShowPassword((prev) => !prev)}
                                aria-label="toggle password visibility"

                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    onChange={onPasswordChange}
                />
            </FormControl>
           {passwordVal.weak && !passwordVal.required && <Typography fontSize="12px" sx={{ color: '#f00', py: '10px' }} >{t('Password is weak')}</Typography>}
            {passwordVal.required && <Typography fontSize="12px" sx={{ color: '#f00', py: '10px' }}>{t('Password is Required')}</Typography>}

           </Box>
       
           
            <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-confirmPassword">{t('Confirm Password')}</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-confirmPassword"
                    type={showConfirmPassword ? 'text' : 'password'}
                    label={t('"Confirm Password"')}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => setShowConfirmPassword((prev) => !prev)}
                                aria-label="toggle password visibility"

                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    onChange={onConfirmPasswordChange}
                />
            </FormControl>
          
            {passwordConfirm === '' && <Typography fontSize="12px" sx={{ color: '#f00', py: '10px' }} >{t('Password Confirm is required')}</Typography>}
            { (passwordConfirm && passwordConfirm !== passwordVal.value) && <Typography fontSize="12px" sx={{ color: '#f00', py: '10px' }}>{t('Password do not match')}</Typography>}
        </Box>

        <Button variant='contained' disabled={(passwordVal.required || passwordVal.invalid) || submitDisabled || (!passwordConfirm || passwordConfirm !== passwordVal.value)} color='primary' onClick={onSubmitHandler} fullWidth>{t('Submit')}</Button>
    </Box>)
};

export default ResetPasswordPage;