import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(initReactI18next) 
    .use(LanguageDetector)// passes i18n down to react-i18next
    .init({
        fallbackLng: 'en',
        resources: {
            en: {
                translation: require('../translations/en.json')
            },
            ar: {
                translation: require('../translations/ar.json')
            }
        },
        detection: {
            order: ['querystring','localStorage', 'navigator'],
            caches: ['localStorage'],
            lookupQuerystring: 'lang'
        },
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

    if(i18n.resolvedLanguage === 'ar'){
        document.documentElement.setAttribute('dir', 'rtl');
    }else{
        document.documentElement.setAttribute('dir', 'ltr');
    }

    i18n.on('languageChanged', (lng) => {
        document.documentElement.setAttribute('lang', lng);
        if(lng === 'ar'){
            document.documentElement.setAttribute('dir', 'rtl');
        }else{
            document.documentElement.setAttribute('dir', 'ltr');
        }
    })


export default i18n;